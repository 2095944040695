import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import { BaseTable } from "components/Table/Atom";
import BaseInstance, { axiosInstance } from "instance/axios";
import { icons } from "modules/icons";
import { useEffect, useState } from "react";
import type { Column } from "react-data-grid";
import { FormatterProps, SelectColumn } from "react-data-grid";
import { TicketRow } from ".";
import DetailModal from "./DetaiModal";
import EditModal from "./EditModal";
import ShippingInfoModal from "./ShippingInfoModal";
import FileModal from "./modal/file/FileModal";

interface Props {
  refreshTickets: (val: any) => any;
}

const deliveryStatement = (shippingType?: "SELF" | "PROVIDER") => {
  if (shippingType === "SELF") return "직접출고";
  if (shippingType === "PROVIDER") return "탁송";
  return "출고 방법 입력";
};

function ReceptionTable(
  orders: any,
  page: number = 1,
  { refreshTickets }: Props,
  setShortColumn: any
) {
  const { openModal, closeModal } = useModal();
  const [refresh, setRefresh] = useState<{ type: "YES" | "NO" }>({
    type: "NO",
  });

  useEffect(() => {
    refreshTickets(refresh);
  }, [refresh, refreshTickets]);

  const ArrowIconUp = () => {
    return (
      <div
        className="up-arrow"
        style={{
          height: "10px",
          cursor: "pointer",
          display: "flex",
        }}
      >
        <icons.ArrowUpIcon />
      </div>
    );
  };
  const ArrowIconDown = () => {
    return (
      <div
        className="up-arrow"
        style={{
          height: "10px",
          cursor: "pointer",
          display: "flex",
        }}
      >
        <icons.ArrowDownIcons />
      </div>
    );
  };
  const columns: readonly Column<TicketRow>[] = [
    {
      ...SelectColumn,
      width: "auto",
      maxWidth: 500,
      resizable: true,
      frozen: false,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "createdAt",
      name: "입고일시",
      resizable: true,
      cellClass: css`
        color: #dde1e8;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 700;
        word-wrap: break-word;
      `,
    },
    {
      key: "plateCode",
      name: "차량번호",
      sortable: true,
      resizable: true,
      //   formatter: (props) => {
      //     return <></>;
      //   },
    },
    {
      key: "vinCode",
      name: "VIN",
      sortable: true,
      resizable: true,
    },
    {
      key: "carModel",
      name: "모델명",
      sortable: true,
      resizable: true,
    },
    {
      key: "customerName",
      name: "고객 이름",
      sortable: true,
      resizable: true,
    },
    {
      key: "mobile",
      name: "고객 연락처",
      sortable: true,
      resizable: true,
    },

    {
      key: "createUserName",
      name: "접수자",
      sortable: true,
      resizable: true,
    },
    {
      key: "stageName",
      name: "현재 단계",
      sortable: true,
      resizable: true,
    },
    {
      key: "status",
      name: "진행상태",
      sortable: true,
      resizable: true,
      headerRenderer: (props) => {
        const handleSort = () => {
          let direction = null;
          if (props.sortDirection === "ASC") {
            setShortColumn({ columnKey: "stepStatus", direction: "DESC" });
            direction = "DESC";
          } else if (props.sortDirection === "DESC") {
            setShortColumn({ columnKey: "createdAt", direction: "DESC" });
            direction = null;
          } else {
            setShortColumn({ columnKey: "stepStatus", direction: "ASC" });
            direction = "ASC";
          }
          setSortColumn([
            { columnKey: props?.column.key, direction: direction },
          ]);
        };
        return (
          <UpDownContainer onClick={handleSort}>
            {props.column.name}
            <UpDowncontain>
              {props.sortDirection === "ASC" && <ArrowIconUp />}
              {props.sortDirection === "DESC" && <ArrowIconDown />}
              {!props.sortDirection && (
                <>
                  <ArrowIconUp />
                  <ArrowIconDown />
                </>
              )}
            </UpDowncontain>
          </UpDownContainer>
        );
      },
    },
    {
      key: "message",
      name: "완료메세지 전송",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const messageAction = async (alreadySent: boolean | undefined) => {
          if (
            window.confirm(
              alreadySent
                ? "이미 전송된 메시지입니다. 한 번 더 전송하시겠습니까?"
                : "메세지를 전송하시겠습니까?"
            )
          ) {
            sendMessage(formatterProps?.row?.id);
          }
        };

        // delete item after confirm
        const sendMessage = async (sendItemId: number) => {
          try {
            await axiosInstance.post(
              `https://mbk.sizl.co.kr/api/ticket/${sendItemId}/done-message`
            );
            setRefresh({ type: "YES" });
          } catch (e) {
            alert("Error! please try again");
            console.log("Tickets delete Error ::: ", e);
          }
        };

        return (
          <SendMessageBtn
            disabled={formatterProps?.row?.stepStatus !== "DONE"}
            isAlreadySent={
              formatterProps?.row?.isStagesDoneMessageSent ?? false
            }
            isAbleToSent={formatterProps?.row?.stepStatus === "DONE"}
            onClick={() =>
              messageAction(formatterProps?.row?.isStagesDoneMessageSent)
            }
          >
            {formatterProps?.row?.isStagesDoneMessageSent ? "전송됨" : "전송"}
          </SendMessageBtn>
        );
      },
    },
    {
      key: "deliveryState",
      name: "출고상태",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <>
            {formatterProps.row.isStagesDoneMessageSent === true && (
              <DeliveryStateBtn
                state={formatterProps?.row?.shippingInfo?.shippingInfoType}
                onClick={() => {
                  setIsReceptionInformOpen(true);
                  setFormatterProps(formatterProps);
                }}
              >
                {deliveryStatement(
                  formatterProps?.row?.shippingInfo?.shippingInfoType
                )}
              </DeliveryStateBtn>
            )}
          </>
        );
      },
    },
    {
      key: "fileUpload",
      name: "첨부파일",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <FileUploadButton
            onClick={() => {
              openModal(
                <FileModal
                  formatterProps={formatterProps}
                  onClose={() => closeModal({})}
                />,
                "",
                ""
              );
            }}
          >
            {"업로드"}
          </FileUploadButton>
        );
      },
    },
    {
      key: "parkingLocation",
      name: "주차위치",
      sortable: true,
      resizable: true,
    },
    {
      key: "options",
      name: "options",
      width: 120,
      sortable: true,
      resizable: true,
      headerCellClass: css`
        justify-content: flex-end;
      `,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: (formatterProps) => {
        // confimation for delete item
        const deleteReceiptAction = async (tid: number) => {
          if (window.confirm("Delete this item?")) {
            deleteItem(tid);
          }
        };

        // delete item after confirm
        const deleteItem = async (deleteItemId: number) => {
          await BaseInstance.ticketsTicketIdDelete(deleteItemId)
            .then(({ data }: any) => {
              setRefresh({ type: "YES" });
            })
            .catch((e) => {
              alert("Error! please try again");
              console.log("Tickets delete Error ::: ", e);
            });
        };

        return (
          <OptionBox>
            <OptionBtn
              onClick={() => {
                setIsDetailModalOpen(true);
                setFormatterProps(formatterProps);
              }}
            >
              <DetailBtn>상세</DetailBtn>
            </OptionBtn>

            <OptionBtn
              onClick={() => {
                setIsEditModalOpen(true);
                setFormatterProps(formatterProps);
              }}
            >
              <icons.PencilIcon />
            </OptionBtn>

            <OptionBtn
              onClick={() => {
                setRefresh({ type: "NO" });
                deleteReceiptAction(formatterProps?.row?.id);
              }}
            >
              <icons.TrashIcon />
            </OptionBtn>
          </OptionBox>
        );
      },
    },
  ];

  // code changes for ticket #564
  useEffect(() => {
    setRows(orders);
  }, [orders]);

  const sortArray = async (sortDirection: any) => {
    if (sortDirection === "DESC" || sortDirection === "ASC") {
      const sortArrayDes = [...orders];
      sortArrayDes.sort((a: { status: number }, b: { status: number }) =>
        sortDirection === "ASC"
          ? a.status > b.status
            ? 1
            : -1
          : a.status < b.status
            ? 1
            : -1
      );
      setRows(sortArrayDes);
    } else {
      setRows(orders);
    }
  };

  const [sortColumn, setSortColumn] = useState<any>(null);
  const [rows, setRows] = useState<any>(); // code changes for ticket #564
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isReceptionInformOpen, setIsReceptionInformOpen] = useState(false);
  const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);
  const [formatterProps, setFormatterProps] = useState<
    FormatterProps<TicketRow, unknown> | undefined
  >();

  useEffect(() => {
    if (page !== page + 1 || page !== page - 1) {
      setSelectedRows(new Set());
    }
  }, [page]);
  return {
    selectedRows,
    Table: () => (
      <>
        <BaseTable
          columns={columns}
          rows={rows}
          rowKeyGetter={(row: TicketRow) => row.id}
          onRowsChange={rows}
          selectedRows={selectedRows}
          onSelectedRowsChange={setSelectedRows}
          sortColumns={sortColumn}
        />
        <ShippingInfoModal
          formatterProps={formatterProps as FormatterProps<TicketRow, unknown>}
          isOpen={isReceptionInformOpen}
          setIsOpen={setIsReceptionInformOpen}
          loadRecords={(value) => {
            setRefresh({ type: "YES" });
          }}
        />
        <EditModal
          formatterProps={formatterProps as FormatterProps<TicketRow, unknown>}
          isOpen={isEditModalOpen}
          setIsOpen={setIsEditModalOpen}
          loadRecords={(value) => {
            setRefresh({ type: "YES" });
          }}
        />
        <DetailModal
          formatterProps={formatterProps as FormatterProps<TicketRow, unknown>}
          isOpen={isDetailModalOpen}
          setIsOpen={setIsDetailModalOpen}
          loadRecords={(value) => {
            setRefresh({ type: "YES" });
          }}
        />
      </>
    ),
  };
}

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
  z-index: 1000;
`;
const OptionBtn = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeliveryStateBtn = styled.button<{ state?: "SELF" | "PROVIDER" }>`
  width: 150px;
  height: 30px;
  background-color: ${({ state }) =>
    !state ? "#00a0ff" : state === "SELF" ? "#47E1A8" : "#BF94E4"};
  color: white;
  font-weight: bold;
`;

const SendMessageBtn = styled.button<{ isAlreadySent: boolean; isAbleToSent: boolean; }>`
  width: 50px;
  height: 30px;
  cursor: ${({ isAbleToSent }) => (isAbleToSent ? "pointer" : "not-allowed")};
  background-color: ${({ isAlreadySent }) => (isAlreadySent ? "#717886" : "#ffb400")};
  color: white; // {({ isAlreadySent }) => (isAlreadySent ? "white" : "")};
  font-weight: bold;
`;

const DetailBtn = styled.button`
  width: 25px;
  height: 30px;
  background-color: #D9D9D9;
  color: black;
`;

const UpDownContainer = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
`;
const UpDowncontain = styled.div`
  margin-bottom: 10px;
`;

const FileUploadButton = styled.div`
  display: flex;
  height: 30px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--Primary-600, #319dff);
`;

export default ReceptionTable;
